header.banner {
    height: 400px;
    width: 100%;
    display: block;
    background-position: top right;
    position: relative;

    &-gallery {
        @include background(url("../images/banners/banner-gallery.jpg"));
        background-repeat: no-repeat;
        background-position: 100% 70%;
        background-size: cover;

        @include mq-max(770px) {
            background-position: 80%;
        }
    }

    &-contact {
        @include background(url("../images/banners/banner-contact.jpg"));
        background-repeat: no-repeat;
        background-position: top right;
        background-size: cover;

        @include mq-max(770px) {
            background-position: 80%;
        }
    }

    &-price {
        @include background(url("../images/banners/banner-price.jpg"));
        background-repeat: no-repeat;
        background-position: top right;
        background-size: cover;

        @include mq-max(770px) {
            background-position: 80%;
        }
    }

    h1 {
        font-size: 3rem;
        text-align: center;
        text-transform: uppercase;
        color: $White;
        font-family: $Libre;
        font-weight: lighter;
        letter-spacing: 3px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        @include transform(translateY(-50%));
        display: block;
    }

}
