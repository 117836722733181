.price {

    width: 100%;
    display: block;
    padding: 2rem 0;
    background: $LightGrey;

    @include mq-mobile {
        padding: 2rem 0 5%;
    }

    h2 {
        text-align: center;
        text-transform: uppercase;
        color: $Black;
        font-size: 2.2rem;
        margin: 1rem auto 3rem;
        font-family: $MonsteratLight;
        letter-spacing: 3px;
    }

    // Layout grid
    .price_grid {
        .service {
            background: $White;
            padding: 2rem;
            overflow: hidden;
        }
        @include clearfix();
        width: 100%;
        display: block;

        .price_lady {
            width: 68%;
            float: left;
            margin-bottom: 2%;
            margin-right: 2%;
            display: block;
        }
        .price_lady_image {
            width: 30%;
            float: left;
            margin-bottom: 2%;
            display: block;
            @include background(url("../images/price_blowdry.jpg") no-repeat);
            background-position: bottom right;
            background-size: cover;
        }
        .price_colour_image {
            width: 18%;
            float: left;
            margin-bottom: 2%;
            margin-right: 2%;
            display: block;
            @include background(url("../images/price_foils.jpg") no-repeat);
            background-position: 40% bottom;
            background-size: cover;
        }
        .price_colour {
            width: 80%;
            float: left;
            display: block;
            margin-bottom: 2%;
        }
        .price_styling {
            width: 68%;
            float: left;
            margin-bottom: 2%;
            margin-right: 2%;
            display: block;
        }
        .price_styling_image {
            width: 30%;
            float: left;
            margin-bottom: 2%;
            display: block;
            @include background(url("../images/price_updo.jpg") no-repeat);
            background-position: center bottom;
            background-size: cover;
        }
        .price_gents {
            width: 49%;
            float: left;
            margin-bottom: 2%;
            margin-right: 2%;
            display: block;
        }
        .price_juniors {
            width: 49%;
            float: left;
            margin-bottom: 2%;
            display: block;
        }
        .price_treatments {
            width: 49%;
            float: left;
            margin-bottom: 2%;
            margin-right: 2%;
            display: block;
            // Modified Styling to handle dual block elements
            background: transparent;
            padding: 0;
        }
        .price_products {
            width: 49%;
            float: left;
            margin-bottom: 2%;
            display: block;
        }
        .price_info {
            display: none;
            width: 100%;
            float: left;
            background: $MatteGrey;
            p {
                text-transform: uppercase;
                color: $White;
                font-family: $MonsteratLight;
                letter-spacing: 2px;
                font-size: 1.2rem;
            }
        }
        // Price Content Styling
        .price_content {
            font-size: 0;

            p {
                font-family: $Monsterat;
                text-transform: uppercase;
                font-size: 1.4rem;
                margin: 0 auto;
                text-align: left;
                letter-spacing: 2px;

                &.text {
                    font-family: $MonsteratLight;
                    font-size: 1.2rem;
                    letter-spacing: normal;
                    margin: 1rem auto;
                    text-transform: none;
                }
                &.subheading {
                    text-transform: lowercase;
                    letter-spacing: normal;
                    font-family: $Monsterat;
                    font-size: 0.8rem;
                    margin: 0 auto 0.5rem;
                    display: inline-block;
                    &_long {
                        width: 75%;
                    }
                    &_short {
                        width: 25%;
                        text-transform: capitalize;
                    }
                    &_below {
                        margin: 0.5rem auto;
                        text-transform: none;
                    }
                }
            }

            ul.column_two {
                list-style: none;
                padding: 0;
                margin: 0;
                font-family: $MonsteratLight;
                li {
                    display: block;
                    width: 100%;
                    padding: 5px 0;
                    font-size: 0;
                    border-bottom: 1px solid $LightGrey;

                    span {
                        font-size: 1.2rem;
                        display: inline-block;
                        width: 50%;
                        vertical-align: middle;
                        &:first-child {
                            width: 70%;
                        }
                        &:last-child {
                            width: 30%;
                        }
                        &.shrink {
                            @include mq-max(520px) {
                                font-size: 0.7rem;
                            }
                        }
                    }
                }
            }
            ul.column_three {
                list-style: none;
                padding: 0;
                margin: 0;
                font-family: $MonsteratLight;
                li {
                    display: block;
                    width: 100%;
                    padding: 5px 0;
                    font-size: 0;
                    border-bottom: 1px solid $LightGrey;

                    &.spacer {
                        margin-top: 1rem;
                    }

                    span {
                        font-size: 1.2rem;
                        display: inline-block;
                        width: 25%;
                        vertical-align: middle;
                        &.title {
                            width: 50%;
                        }
                        &.spread_two {
                            width: 50%;
                        }
                    }
                }
            }
        }

        .treatment_content {
            margin-bottom: 4%;
            padding: 2rem;
            background: $White;
        }
        .info_content {
            background-color: $MatteGrey;
            color: $White;
            text-transform: uppercase;
            padding: 2rem;
            font-family: $MonsteratLight;

            p.text {
                text-transform: inherit;
                letter-spacing: 2px;
            }
        }

        //Tablet Overide
        @include mq-tablet {
            // make Box A Responsive Square For Images
            .square-box {
                position: relative;
                overflow: hidden;
                &:before {
                    content: "";
                    display: block;
                    padding-top: 100%;
                }
            }
            // Adjust Font Sizes
            .price_content {
                p {
                    font-size: 1.2rem;
                    &.text {
                        font-size: 1rem;
                    }
                }
                ul.column_two li span {
                    font-size: 1rem;
                }
                ul.column_three li span {
                    font-size: 1rem;
                }
            }

            // Re Layout Boxes
            .price_lady {
                width: 100%;
                margin-right: 0;
            }
            .price_lady_image {
                width: 49%;
                margin-right: 2%;
                // height: 230px;
            }
            .price_colour_image {
                width: 49%;
                margin-right: 0;
                // height: 230px;
            }
            .price_colour {
                width: 100%;
                margin-right: 0;

                .price_content ul li span.title {
                    padding-right: 10%;
                }
            }
        }

        // Mobile Override
        @include mq-max(640px) {
            // make Box A Responsive Square For Images
            .square-box-mobile {
                position: relative;
                overflow: hidden;
                &:before {
                    content: "";
                    display: block;
                    padding-top: 100%;
                }
            }

            .price_styling {
                width: 100%;
                margin-right: 0;
            }
            .price_styling_image {
                width: 100%;
                margin-right: 0;
            }
            .price_gents {
                width: 100%;
                margin-right: 0;
            }
            .price_juniors {
                width: 100%;
                margin-right: 0;
            }
            .price_treatments {
                width: 100%;
                margin-right: 0;
                margin-bottom: 2%;
                .treatment_content {
                    margin: 0;
                }
                .info_content {
                    display: none;
                }
            }
            .price_products {
                width: 100%;
                margin: 0;
            }
            .price_info {
                margin-top: 2%;
                display: block;
                text-align: center;
            }
        }

        @include mq-max(399px) {
            // Adjust Font Sizes
            .price_content {
                p {
                    font-size: 1.1rem;
                    &.text {
                        font-size: 0.85rem;
                    }
                }
                ul.column_two li span {
                    font-size: 0.85rem;
                }
                ul.column_three li span {
                    font-size: 0.85rem;
                }
            }

        }



    }

    //
}
