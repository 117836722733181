$mobile: 500px;
$tablet: 900px;
$desktop: 901px;
$container: 1280px;

@mixin mq-mobile(){
    @media screen and (max-width: $mobile){
        @content;
    }
}
@mixin mq-tablet(){
    @media screen and (max-width: $tablet){
        @content;
    }
}
@mixin mq-desktop(){
    @media screen and (min-width: $desktop){
        @content;
    }
}
@mixin mq-max($viewport-width){
    @media screen and (max-width: #{$viewport-width}){
        @content;
    }
}
@mixin mq-min($viewport-width){
    @media screen and (min-width: #{$viewport-width}){
        @content;
    }
}

