// SLideshow
.hollandandco-slider {
    visibility: hidden;
}
.unslider {
    height: 100%;
    overflow: visible;
    @include radial-gradient(circle at 50% 50%,  $LightGradient 10%, $DarkGradient 60%, $fallback: $DarkGradient);

    // Full Height Slider
    .hollandandco-slider {
        height: 100%;
        ul {
            height: 100%;
        }
    }

    // Slide Styling
    .hollandandco_slide {
        height: 100%;


        &-one {
            height: 100%;
            width: 100%;
            display: block;
            background-size: contain;
            background-position: bottom center;
            background-repeat: no-repeat;

            @include mq-tablet() {
                background-size: cover;
                background-position: center;
            }
            @include background-image(url("../../dist/images/slider/model-long-1.png"));
        }
        &-two {
            height: 100%;
            width: 100%;
            display: block;
            background-size: contain;
            background-position: bottom center;
            background-repeat: no-repeat;

            @include mq-tablet() {
                background-size: cover;
                background-position: center;
            }
            @include background-image(url("../../dist/images/slider/model-curl-1.png"));
        }
        &-three {
            height: 100%;
            width: 100%;
            display: block;
            background-size: contain;
            background-position: bottom center;
            background-repeat: no-repeat;

            @include mq-tablet() {
                background-size: cover;
                background-position: center;
            }
            @include background-image(url("../../dist/images/slider/model-bob-1.png"));
        }

        .container {
            position: relative;
            height: 100%;

            p {
                text-align: center;
                font-family: $Libre;
                font-size: 3rem;
                width: 80%;
                margin: 0 auto;
                text-transform: uppercase;
                color: $White;
                letter-spacing: 2px;
                @include position(absolute, 50% 0 null 0);
                @include transform(translateY(-50%));

                @include mq-mobile {
                    font-size: 1.4rem;
                }
            }
        }
    }

    // Unslider Arrow
    .unslider-arrow {
        top: 50%;
        @include transform(translateY(-50%));
        color: $White;
        z-index: 50;
    }
    .unslider-arrow.next {
        width: 30px;
        height: 30px;
        @include background-image(url("../../dist/images/svg/arrow-right.svg"));
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .unslider-arrow.prev {
        width: 30px;
        height: 30px;
        @include background-image(url("../../dist/images/svg/arrow-left.svg"));
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }

    // Dots
    .unslider-nav {
        position: absolute;
        bottom: 80px;
        left: 0;
        right: 0;
        z-index: 20;
    }

    // Custom arrow down to content
    .unslider-scrolldown {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 0;
        z-index: 20;

        p.unslider-arrow-text {
            font-size: 0.8rem;
            font-family: $Monsterat;
            color: $White;
            margin: 0 auto 0.5rem;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
        a.unslider-arrow-down {
            width: 30px;
            height: 30px;
            @include background-image(url("../../dist/images/svg/arrow-bottom.svg"));
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            display: inline-block;
            margin: 0 auto;
        }
    }

}


.about {

    background: $LightGrey;
    padding: 2rem 0;
    @include mq-mobile {
        padding: 2rem 0 5%;
    }

    // Heading Styling
    h2 {
        text-align: center;
        text-transform: uppercase;
        color: $Black;
        font-size: 2.2rem;
        margin: 1rem auto 3rem;
        font-family: $MonsteratLight;
        letter-spacing: 3px;
    }

    // About Content Styling
    .about_content {
        position: relative;
        width: 100%;
        display: block;
        @include clearfix();

        // Article Styling
        &-article {
            padding: 2rem;
            background: $White;
            z-index: 5;
            width: 73%;
            position: absolute;
            left: 0;
            top: 50%;
            @include mq-min(1081px) {
                @include transform(translateY(-50%));
            }
            img {
                max-width: 300px;
                margin: 0 auto;
            }
            p {
                font-size: 1.1rem;
                font-family: $MonsteratLight;
                color: $Black;
            }

            @include mq-max(1080px) {
                position: relative;
                top: 0;
                left: 0;
                float: none;
                width: 100%;
            }
        }

        // Image Styling
        // * Hide Image at < 1080px
        &-image{
            width: 32%;
            float: right;
            right: 0;
            z-index: 3;

            @include mq-max(1080px) {
                display: none;
            }
        }
    }


}



.options {
    width: 100%;
    background: $White;
    padding: 2rem 0;

    @include mq-mobile {
        padding: 5% 0;
    }

    .row {
        @include clearfix();
        width: 100%;
    }

    .col-4 {
        width: 32%;
        margin-right: 2%;
        float: left;
        &:last-child {
            margin-right: 0;
        }
        @include mq-max(640px) {
            width: 100%;
            float: none;
            margin: 0 0 5% 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    figure {
        padding: 0;
        margin: 0 auto;
        position: relative;
        overflow: hidden;

        figcaption {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 1rem;
            text-transform: uppercase;
            text-align: center;
            color: $White;
            background: transparentize($Black, 0.3);
            font-family: $MonsteratLight;
            font-size: 1.6rem;
            letter-spacing: 2px;
            @include transition(background 1.0s ease);
        }
    }

    a > figure > img {
        @include transition(all 1.0s ease);
    }
    a:hover > figure > img {
        @include filter(grayscale(100%));
        @include transform(rotate(0.8deg) scale(1.2));
    }
    a:hover > figure > figcaption {
        background: transparentize($Black, 0);
    }
}
