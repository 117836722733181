.gallery {

    width: 100%;
    display: block;
    padding: 2rem 0;
    background: $LightGrey;

    h2 {
        text-align: center;
        text-transform: uppercase;
        color: $Black;
        font-size: 2.2rem;
        margin: 1rem auto 3rem;
        font-family: $MonsteratLight;
        letter-spacing: 3px;
    }



    .hollandco_lightbox {
        display: block;
        @include clearfix();

        a {
            width: 49%;
            display: block;
            margin-bottom: 2%;
            overflow: hidden;
            &.last {
                margin-bottom: 0;
            }
            &:nth-child(odd) {
                float: left;
            }
            &:nth-child(even) {
                float: right;
            }
            &:last-child(-n+2) {
                margin-bottom: 0;
            }

            > img {
                @include transition(all 1.0s ease);
            }
            &:hover > img{
                @include transform(rotate(0.8deg) scale(1.2));
            }
        }

    }

}
