* {
    
    box-sizing: border-box;

    a {
        color: inherit;
        text-decoration: none;
    }

    figure {
        padding: 0;
        margin: 0;
        display: block;
    }

    ul {
        list-style: none;
    }

}

img {
    width: 100%;
    margin: 0 auto;
    display: block;
}

html, body {
    height: 100%;
    width: 100%;
    font-size: 16px;
}
