.container {
    max-width: $container;
    width: 100%;
    padding: 0 2%;
    margin: 0 auto;
    display: block;

    @include mq-mobile {
        padding: 0 4%;
    }
}
