.findus {

    display: block;
    width: 100%;
    margin: 0 auto;
    background: $LightGrey;
    padding: 2rem 0;

    h2 {
        text-align: center;
        text-transform: uppercase;
        color: $Black;
        font-size: 2.2rem;
        margin: 1rem auto 3rem;
        font-family: $MonsteratLight;
        letter-spacing: 3px;
    }

    .findus_content {
        position: relative;
        width: 100%;
        display: block;
        @include clearfix();

        &-image {
            width: 35%;
            float: left;
            right: 0;
            z-index: 3;

            @include mq-max(1080px) {
                display: none;
            }

        }

        &-text {

            padding: 2rem;
            background: $White;
            z-index: 5;
            width: 73%;
            position: absolute;
            right: 0;
            top: 50%;
            @include mq-min(1081px) {
                @include transform(translateY(-50%));
            }

            @include mq-max(1080px) {
                position: relative;
                top: 0;
                width: 100%;
                @include background(linear-gradient(rgba(white, 0.9), rgba(white, 0.9)), url("../images/where-to-find-us.jpg"));
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            img {
                max-width: 300px;
                margin: 0 auto;
            }

            .address_times_container {
                @include clearfix();
                margin: 1rem auto;

                .address_block {
                    width: 58%;
                    float: left;
                    @include mq-max(1080px) {
                        width: 52%;
                    }
                    @include mq-max(640px) {
                        width: 100%;
                        float: none;
                        text-align: center;
                        margin-bottom: 1.5rem;
                    }

                    address {
                        font-style: normal;
                        text-transform: uppercase;
                        font-family: $MonsteratLight;
                        line-height: 31px;

                    }
                }
                .times_block {
                    width: 40%;
                    float: right;
                    @include mq-max(1080px) {
                        width: 48%;
                    }
                    @include mq-max(640px) {
                        width: 100%;
                        float: none;
                    }

                    ul {
                        padding: 0;
                        list-style: none;
                        margin: 0 auto;
                        display: table;
                        font-family: $MonsteratLight;
                        li {
                            display: table-row;
                            width: 100%;
                            span {
                                display: table-cell;
                                width: 50%;
                                padding: 4px 8px;
                                @include mq-max(640px) {
                                    &:first-child {
                                        text-align: left;
                                        width: auto;
                                    }
                                    &:last-child {
                                        text-align: right;
                                        width: auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .social {
                width: 100%;
                display: block;

                > ul {
                    display: inline-block;
                    list-style: none;
                    width: auto;
                    padding: 0;
                    margin: 0;
                    vertical-align: middle;
                     li {
                         display: inline-block;
                         width: 50px;
                         height: 50px;
                         margin-right: 10px;
                         &:last-child {
                             margin-right: 0;
                         }

                         img {
                             @include transition(filter 0.3s ease);
                             &:hover {
                                 @include filter(hue-rotate(0deg) brightness(0.8));
                             }
                         }
                     }

                     @include mq-max(640px) {
                        display: block;
                        text-align: center;
                        width: 100%;
                        margin: 2rem auto;
                     }
                }

                p {
                    display: inline-block;
                    font-family: $MonsteratLight;
                    font-size: 1.2rem;
                    vertical-align: middle;
                    margin-left: 20%;
                    @include mq-max(640px) {
                       display: block;
                       text-align: center;
                       width: 100%;
                       margin: 0 auto;
                    }
                }
            }
        }

    }
}

.map {
    height: 400px;
    width: 100%;
    display: block;
    background: $LightGrey;
    margin-top: 2rem;
}

.contact {
    display: block;
    width: 100%;
    margin: 0 auto;
    background: $White;
    padding: 2rem 0;

    .flash-msg {
        text-align: center;
    }
    .success-msg {
        color: green;
        font-size: 1.2rem;
    }
    .error-msg {
        color: red;
        font-size: 1.2rem;
    }
    #form-messages {
        text-align: center;
    }
    .subHeading {
        text-align: center;
        display: block;
        width: 100%;
    }

    h2 {
        text-align: center;
        text-transform: uppercase;
        color: $Black;
        font-size: 2.2rem;
        margin: 1rem auto 3rem;
        font-family: $MonsteratLight;
        letter-spacing: 3px;
    }

    form {
        padding: 0;
        width: 100%;
        max-width: 960px;
        margin: 0 auto;
        @include clearfix();
        font-family: $MonsteratLight;
        .col-12 {
            display: block;
            width: 100%;
            margin: 0 auto;
            float: left;
        }
        .col-6 {
            float: left;
            width: 49%;
            display: block;
            &:nth-child(even) {
                float: right;
            }

            @include mq-tablet {
                float: none;
                width: 100%;
                &:nth-child(even) {
                    float: none;
                }
            }
        }

        input {
            width: 100%;
            padding: 15px 20px;
            font-size: 1.2rem;
            border: 0;
            background: $LightGrey;
            margin-bottom: 1.2rem;
            outline: none;
            @include placeholder {
                text-transform: uppercase;
                letter-spacing: 2px;
            }
            @include mq-tablet {
                font-size: 1rem;
            }

        }

        input[type="submit"] {
            margin: 0 auto;
            max-width: 150px;
            text-transform: uppercase;
            text-align: center;
            display: block;
            background: $MatteGrey;
            color: $White;
            @include transition(filter 0.3s ease);
            &:hover {
                @include filter(hue-rotate(0deg) brightness(0.8));
            }
        }

        textarea {
            width: 100%;
            padding: 15px 20px;
            font-size: 1.2rem;
            border: 0;
            background: $LightGrey;
            outline: none;
            resize: none;
            height: 127px;
            @include mq-tablet {
                font-size: 1rem;
                margin-bottom: 1rem;
            }
            @include placeholder {
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }
    }

}
