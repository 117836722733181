#main_nav {
    max-width: $container;
    width: 96%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    background: $White;
    margin: 0 auto;
    z-index: 900;
    color: $Black;
    height: 51px;
    @include mq-max(640px) {
        height: 51px;
        @include transform(translate3d(0px, 0px,0px));
        // @include transition(all 0.3s linear);
        &.menu-active {
            @include transform(translate3d(0px,0px,0px));
            height: 280px;
        }
    }

    .mobile-nav {
        display: none;
        @include mq-max(640px) {
            display: block;
            width: 100%;
            .mobile-options {
                list-style: none;
                padding: 0;
                margin: 0 auto;
                text-align: center;
                opacity: 0;
                visibility: hidden;
                @include transition(opacity 0.5s ease, visibility 0s ease 0s);
                padding-top: 55px;
                height: 0;


                li {
                    display: block;
                    padding: 1rem 2%;
                    text-align: center;
                    font-family: $MonsteratLight;
                    text-transform: uppercase;
                    font-size: 1.2rem;
                    color: $Black;
                    text-align: center;

                    a {
                        text-transform: inherit;
                        font: inherit;
                    }
                }
            }
        }
    }
    &.menu-active .mobile-nav .mobile-options {
        @include transition(opacity 0.5s ease, visibility 0.5s ease 0s);
        opacity: 1;
        visibility: visible;
    }

    // Container
    .display-nav {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        @include mq-max(640px) {
            height: 51px;
            background: $White;
        }
    }

    .holland_number {
        color: inherit;
        width: auto;
        display: inline-block;
        font-family: $Monsterat;
        position: absolute;
        left: 1.5rem
    }
    .holland_logo {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            @include transition(all 0.3s ease 0.5s);
        }
        .logo {
            // Large Logo Styling
            &-l {
                width: 200px;
                visibility: visible;
                opacity: 1;
                z-index: 2;
                @include transform(translateY(0));
                @include mq-max(640px) {
                    display: none !important;
                }
            }
            // Small Logo Styliing
            &-s {
                opacity: 1;
                visibility: visible;
                z-index: 1;
                width: 51px;
                @include transform(translateY(0));
                @include mq-max(640px) {
                    visibility: visible !important;
                }
            }
            // hide Logo
            @include mq-min(641px) {
                &-hide {
                    @include transform(translateY(-100%));
                    @include transition(all 0.3s ease);
                }
            }
        }

    }
    // ----
    .holland_hamburger {
        display: inline-block;
        width: auto;
        margin: 0 0 0 auto;
        position: absolute;
        right: 1.5rem;
        top: 50%;
        @include transform(translateY(-50%));
        font-size: 1.7rem;
        z-index: 40;

        .hamburger {
            outline: none;
            padding: 0;
        }
    }

    .menu_options {
        // display: none;
        @include mq-max(640px) {
            display: none;
        }

        ul.desktop-options {
            list-style: none;
            padding: 0;
            margin: 0 auto;
            text-align: center;

            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            @include transform(translateY(-50%));

            li {
                display: inline-block;
                text-transform: uppercase;
                font: $Monsterat;
                color: $Black;
                padding: 0 1rem;
                position: relative;
                opacity: 0;
                visibility: hidden;
                @include transition(opacity 0.5s ease, visibility 0s ease 0.5s);
                &.fall_in {
                    @include transition(opacity 0.5s ease 0.4s, visibility 0s ease 0s);
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}
