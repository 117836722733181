footer {
    .sponsors {
        padding: 2rem 0;
        background: $MatteGrey;

        p {
            text-transform: uppercase;
            text-align: center;
            color: $White;
            font-family: $MonsteratLight;
        }

        ul.logos {
            display: block;
            width: auto;
            text-align: center;
            padding: 0;
            margin: 0 auto;
            li {
                display: inline-block;
                padding: 10px 30px;
                width: 25%;
                display: inline-block;
                vertical-align: middle;
                margin-left: -4px;
                &:nth-child(1) { img { width: 75%; } }
                &:nth-child(2) { img { width: 55%; } }
                &:nth-child(3) { img { width: 70%; } }

                @include mq-max(640px) {
                    width: 50%;
                }
            }
        }
    }

    .detailed {
        margin-top: 2rem;
        padding: 2rem 0;
        background: $Black;
        text-align: center;

        img {
            max-width: 300px;
            margin: 0 auto;

            &.promologo {
                margin: 2rem auto 0;
                max-width: 200px;
            }
        }

        ul.social_icon {
            display: inline-block;
            list-style: none;
            width: auto;
            padding: 0;
            margin: 2rem auto 1rem;
            vertical-align: middle;
             li {
                 display: inline-block;
                 width: 50px;
                 height: 50px;
                 margin-right: 10px;
                 &:last-child {
                     margin-right: 0;
                 }

                 img {
                     @include transition(filter 0.3s ease);
                     &:hover {
                         @include filter(hue-rotate(0deg) brightness(0.8));
                     }
                 }
             }

             @include mq-max(640px) {
                display: block;
                text-align: center;
                width: 100%;
                margin: 2rem auto;
             }
        }

        address {
            text-transform: uppercase;
            font-style: normal;
            text-align: center;
            font-size: 1.2rem;
            font-family: $MonsteratLight;
            color: $MatteGrey;
            @include mq-max(740px) {
                span {
                    // display: none;
                    font-size: 0;
                    display: block;
                }
            }
        }

        p.telephone {
            text-transform: uppercase;
            font-style: normal;
            text-align: center;
            font-size: 1.4rem;
            font-family: $MonsteratLight;
            color: $MatteGrey;
        }

        p.slug {
            font-size: 0.75rem;
            color: $White;
            text-align: center;
            text-transform: uppercase;

        }
    }
}
